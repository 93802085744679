import React, { useCallback, useEffect, useState } from 'react';
import { Ring } from '@uiball/loaders';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import { throttle } from 'lodash';
import { useStats } from '../../shared/hooks/useStats';
import { FilterOptionType } from '../../shared/models';
import { useSelector } from '../../store/store';
import m from '../../styles/style/_shared.module.scss';
import st from '../stats/stats.module.scss';
import s from './tenant-management.module.scss';
import TenantGroup, { workflowStates } from './TenantGroup';

interface Props {}

const inboxSearchOptions = {
  threshold: 0.5,
  keys: ['name'], // Assuming you're only searching by name within inboxes
  includeScore: true, // Optional, depending on whether you want to use scoring for further filtering or ordering
  ignoreLocation: true,
  ignoreFieldNorm: true,
};

const TenantManagement: React.FC<Props> = (props) => {
  const struct = useSelector((state) => state.documents.structure);
  const [statsCount, setStatsCount] = useState(1);
  const [searchResults, setSearchResults] = useState<any[]>();
  const { stats, loading } = useStats(statsCount);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setStatsCount((prev) => prev + 1);
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const fuse = new Fuse(struct?.tenants, {
    ignoreLocation: true,
    ignoreFieldNorm: true,
    includeScore: true, // Optional, depending on whether you want to use scoring for further filtering or ordering
    threshold: 0.9,
    keys: [
      'id',
      {
        name: 'inboxes.name',
      },
    ],
  });

  useEffect(() => {
    if (!searchResults && struct?.tenants) {
      setSearchResults(struct.tenants);
    }
  }, [searchResults, struct]);

  const handleMatches = useCallback(
    throttle((value, fuse) => {
      var tenantResults = fuse.search(value).sort((a, b) => a.score - b.score);
      const filteredResults = tenantResults
        .map((tenantResult) => {
          if (
            tenantResult.item.id.includes(value) &&
            value.length >= 3 &&
            tenantResult.item.inboxes.length !== 0
          ) {
            return tenantResult.item;
          }
          const inboxesFuse = new Fuse(tenantResult.item.inboxes, inboxSearchOptions);
          const matchedInboxes = inboxesFuse.search(value).map((result) => result.item);
          return {
            ...tenantResult.item,
            inboxes: matchedInboxes,
          };
        })
        .filter((e) => e.inboxes.length !== 0);

      setSearchResults(filteredResults);
    }, 250),
    []
  );

  const handleInput = (value) => {
    if (value === '') {
      setSearchResults(struct?.tenants);
    } else {
      handleMatches(value, fuse);
    }
  };

  return (
    <div className={m.container}>
      <div className={m.page_header}>
        <h1 className={m.page_title}>Tenant Management</h1>
      </div>
      <div className={st.sub_stats}>
        {Object.entries(workflowStates).map(([et, v]) => {
          var stat = stats?.workflowStats[et as keyof typeof workflowStates] ?? 0;
          return (
            <div className={st.stat} key={et}>
              <div
                className={st.stat_number}
                style={{ background: workflowStates[et as keyof typeof workflowStates] }}
              >
                {stat}
              </div>
              <span className={st.stat_text}>{et.toLowerCase()}</span>
            </div>
          );
        })}
      </div>
      <div className={s.tools}>
        <input
          onChange={(e) => handleInput(e.target.value)}
          type={'text'}
          placeholder={'Filter Results'}
          className={s.search}
        ></input>
      </div>

      <div className={s.table}>
        <div className={s.header}>
          <button className={clsx(s.header_name, s.first)}>
            <span>Name</span>
          </button>
          <button className={clsx(s.header_name, s.second)}>
            <span> In Progress Documents</span>
          </button>
          <button className={clsx(s.header_name, s.second)}>
            <span> Open Documents</span>
          </button>
          <div className={s.third}>Reprocess</div>
        </div>
        <div className={s.tenant_groups}>
          {searchResults?.map((tenant) => {
            return <TenantGroup key={tenant.id} tenant={tenant} stats={stats} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default TenantManagement;
